
html {
  @apply bg-convLightBlue;
}

body {
  @apply antialiased;
}




.textStroke {
  text-stroke: 1px white;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white;
  color: transparent;
  /* text-shadow:
   -1px -1px 0 #ffffff,  
    1px -1px 0 #ffffff,
    -1px 1px 0 #ffffff,
     1px 1px 0 #ffffff; */
}

.blueOut {
  @apply grayscale mix-blend-screen transition-all duration-500 ;
}

.blueOut:hover {
  @apply md:grayscale-0 md:mix-blend-normal;
}


button, .button {
  @apply font-bagnard px-5 pt-2 pb-1.5 rounded-full bg-white opacity-90 text-convBlue text-sm md:text-xl transition-opacity duration-500;
}


.webkit-fill {
  height: 100vh;
  height: 100cqh;
}


@keyframes hor-movement {
  from {
    left: 0%;
  }
  to {
    left: calc(100% - 349px);
  }
}

@keyframes ver-movement {
  from {
   top: 0%;
  }
  to {
   top: calc(100% - 119px);
  }
}

.textBounce {
  animation-name: hor-movement, ver-movement;
  animation-duration: 7.141s, 5.414s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  @apply absolute z-50 text-4xl text-center font-bold opacity-90;
}



/* purgecss start ignore */

/* media-player iframe {
  @apply overflow-hidden;
} */

.twic-blur {
    opacity: 0;
    will-change: opacity;
    transition: opacity 1s linear, transform .75s ease-out;
  }

  .twic-done {
    opacity: 1;
  }

  .twic-done ~ .swipePic {
    @apply -translate-y-full; 
  }

/* purgecss end ignore */