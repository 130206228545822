@import "tailwindcss/base";
@import "./swiper.css";
@import "./typography.css";

@import "./video/theme.css";
@import "./video/video.css";
@import "tailwindcss/components";

@import "./menu.css";
@import "./layout.css";

/* @import "./plyr2.css"; */
/* @import "./menu.css"; */



@import "tailwindcss/utilities";