

#hamburger {
    @apply relative top-[-6px] left-[-8px] ;
}

#hamburgerOuter {
    @apply h-10 w-10 rounded-full bg-white cursor-pointer flex items-center justify-center opacity-90;
}

#hamburger div {
    @apply h-[2px] w-4 bg-convBlue transition-all duration-200 absolute top-0 left-0;
}



#hamburger div:nth-child(1) {
    top: 0px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }
  
  #hamburger div:nth-child(2) {
    top: 5px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }
  
  #hamburger div:nth-child(3) {
    top: 10px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }
  
  #hamburger.active div:nth-child(1) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 0px;
    left: 3px;
  }
  
  #hamburger.active div:nth-child(2) {
    width: 0%;
    opacity: 0;
  }
  
  #hamburger.active div:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    top: 11px;
    left: 3px;
  }


  #dropDownMenu.active {
    @apply opacity-90 pointer-events-auto duration-300
  }
