/* purgecss start ignore */

/*
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * Player
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 */

[data-media-player] {
  width: 100%;
  display: inline-flex;
  align-items: center;
  position: relative;
  contain: style;
  box-sizing: border-box;
  user-select: none;
}

:where([data-media-player][data-view-type='video']) {
  aspect-ratio: 16 / 9;
}

[data-media-player]:focus,
[data-media-player]:focus-visible {
  outline: none;
}

[data-media-player][data-view-type='video'][data-started]:not([data-controls]) {
  pointer-events: auto;
  cursor: none;
}

[data-media-player] slot {
  display: contents;
}

/*
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * Provider
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 */

[data-media-provider] {
  display: flex;
  position: relative;
  box-sizing: border-box;
  align-items: center;
  border-radius: inherit;
  width: 100%;
  aspect-ratio: inherit;
  overflow: hidden;
}

[data-media-player]:not([data-view-type='audio']) [data-media-provider],
[data-media-player][data-fullscreen] [data-media-provider] {
  height: 100%;
}

[data-media-player][data-view-type='audio'] [data-media-provider] {
  display: contents;
  background-color: unset;
}

[data-media-provider] audio {
  width: 100%;
}

:where([data-media-provider] video),
:where([data-media-provider] iframe) {
  aspect-ratio: inherit;
  display: inline-block;
  height: auto;
  object-fit: contain;
  touch-action: manipulation;
  border-radius: inherit;
  width: 100%;
}

[data-media-provider] iframe {
  height: 100%;
}

[data-media-player][data-view-type='audio'] video,
[data-media-player][data-view-type='audio'] iframe {
  display: none;
}

[data-media-player][data-fullscreen] video {
  height: 100%;
}

iframe.vds-youtube[data-no-controls] {
  height: 1000%;
}

.vds-blocker {
  position: absolute;
  inset: 0;
  width: 100%;
  height: auto;
  aspect-ratio: inherit;
  pointer-events: auto;
  border-radius: inherit;
  z-index: 1;
}

[data-ended] .vds-blocker {
  background-color: black;
}

.vds-icon:focus {
  outline: none;
}

/*
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * Google Cast
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 */

.vds-google-cast {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #dedede;
  font-family: sans-serif;
  font-weight: 500;
}

.vds-google-cast svg {
  --size: max(18%, 40px);
  width: var(--size);
  height: var(--size);
  margin-bottom: 8px;
}

.vds-google-cast-info {
  font-size: calc(var(--media-height) / 100 * 6);
}

:where(.vds-buffering-indicator) {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  z-index: 1;
}

:where(.vds-buffering-indicator) :where(.vds-buffering-icon, .vds-buffering-spinner) {
  opacity: 0;
  pointer-events: none;
  transition: var(--media-buffering-transition, opacity 200ms ease);
}

:where(.vds-buffering-indicator)
  :where(.vds-buffering-icon, svg.vds-buffering-spinner, .vds-buffering-spinner svg) {
  width: var(--media-buffering-size, 96px);
  height: var(--media-buffering-size, 96px);
}

:where(.vds-buffering-indicator) :where(.vds-buffering-track, circle[data-part='track']) {
  color: var(--media-buffering-track-color, #f5f5f5);
  opacity: var(--media-buffering-track-opacity, 0.25);
  stroke-width: var(--media-buffering-track-width, 8);
}

:where(.vds-buffering-indicator) :where(.vds-buffering-track-fill, circle[data-part='track-fill']) {
  color: var(--media-buffering-track-fill-color, var(--media-brand));
  opacity: var(--media-buffering-track-fill-opacity, 0.75);
  stroke-width: var(--media-buffering-track-fill-width, 9);
  stroke-dasharray: 100;
  stroke-dashoffset: var(--media-buffering-track-fill-offset, 50);
}

:where([data-buffering]) :where(.vds-buffering-icon, .vds-buffering-spinner) {
  opacity: 1;
  animation: var(--media-buffering-animation, vds-buffering-spin 1s linear infinite);
}

@keyframes vds-buffering-spin {
  to {
    transform: rotate(360deg);
  }
}

/*
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * Buttons
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 */

:where(.vds-button) {
  -webkit-tap-highlight-color: transparent;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  appearance: none;
  background: none;
  outline: none;
  border: none;
  padding: var(--media-button-padding, 0px);
  border-radius: var(--media-button-border-radius, 8px);
  color: var(--media-button-color, var(--media-controls-color, #f5f5f5));
  width: var(--media-button-size, 40px);
  height: var(--media-button-size, 40px);
  transition: transform 0.2s ease-out;
  contain: layout style;
  cursor: pointer;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;
  flex-shrink: 0;
}

.vds-button {
  border: var(--media-button-border);
}

:where([data-fullscreen] .vds-button) {
  width: var(--media-fullscreen-button-size, 42px);
  height: var(--media-fullscreen-button-size, 42px);
}

@media screen and (max-width: 599px) {
  :where([data-fullscreen] .vds-button) {
    width: var(--media-sm-fullscreen-button-size, 42px);
    height: var(--media-sm-fullscreen-button-size, 42px);
  }
}

:where(.vds-button .vds-icon) {
  width: var(--media-button-icon-size, 80%);
  height: var(--media-button-icon-size, 80%);
  border-radius: var(--media-button-border-radius, 8px);
}

:where(.vds-menu-button .vds-icon) {
  display: flex !important;
}

:where(.vds-button[aria-hidden='true']) {
  display: none !important;
}

@media (hover: hover) and (pointer: fine) {
  .vds-button:hover {
    background-color: var(--media-button-hover-bg, rgb(255 255 255 / 0.2));
  }

  .vds-button:hover {
    transform: var(--media-button-hover-transform, scale(1));
    transition: var(--media-button-hover-transition, transform 0.2s ease-in);
  }
}

@media (pointer: coarse) {
  .vds-button:hover {
    border-radius: var(--media-button-touch-hover-border-radius, 100%);
    background-color: var(--media-button-touch-hover-bg, rgb(255 255 255 / 0.2));
  }
}

:where(.vds-button:focus) {
  outline: none;
}

:where(.vds-button[data-focus]) {
  box-shadow: var(--media-focus-ring);
}

/*
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * Live Button
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 */

:where(.vds-live-button) {
  min-width: auto;
  min-height: auto;
  width: var(--media-live-button-width, 40px);
  height: var(--media-live-button-height, 40px);
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  appearance: none;
  background: none;
  outline: none;
  border: none;
}

:where(.vds-live-button-text) {
  background-color: var(--media-live-button-bg, #8a8a8a);
  border-radius: var(--media-live-button-border-radius, 2px);
  color: var(--media-live-button-color, #161616);
  font-family: var(--media-font-family, sans-serif);
  font-size: var(--media-live-button-font-size, 12px);
  font-weight: var(--media-live-button-font-weight, 600);
  letter-spacing: var(--media-live-button-letter-spacing, 1.5px);
  padding: var(--media-live-button-padding, 1px 4px);
  transition: color 0.3s ease;
}

:where(.vds-live-button[data-focus] .vds-live-button-text) {
  box-shadow: var(--media-focus-ring);
}

:where(.vds-live-button[data-edge]) {
  cursor: unset;
}

:where(.vds-live-button[data-edge] .vds-live-button-text) {
  background-color: var(--media-live-button-edge-bg, #dc2626);
  color: var(--media-live-button-edge-color, #f5f5f5);
}

@media (pointer: fine) {
  :where(.vds-live-button:hover) {
    background-color: unset;
  }
}

/*
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * States
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 */

/* Play Button */
.vds-button:not([data-paused]) .vds-play-icon,
.vds-button[data-ended] .vds-play-icon,
.vds-button[data-paused] .vds-pause-icon,
.vds-button[data-ended] .vds-pause-icon,
.vds-button:not([data-ended]) .vds-replay-icon,
/* PIP Button */
.vds-button[data-active] .vds-pip-enter-icon,
.vds-button:not([data-active]) .vds-pip-exit-icon,
/* Fullscreen Button */
.vds-button[data-active] .vds-fs-enter-icon,
.vds-button:not([data-active]) .vds-fs-exit-icon,
/* Caption Button */
.vds-button:not([data-active]) .vds-cc-on-icon,
.vds-button[data-active] .vds-cc-off-icon,
/* Mute Button */
.vds-button:not([data-muted]) .vds-mute-icon,
.vds-button:not([data-state='low']) .vds-volume-low-icon,
.vds-button:not([data-state='high']) .vds-volume-high-icon {
  display: none;
}

/*
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * Captions
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 */

:where(.vds-captions) {
  /* Recommended settings in the WebVTT spec (https://www.w3.org/TR/webvtt1). */
  --overlay-padding: var(--media-captions-padding, 1%);
  --cue-color: var(--media-user-text-color, var(--media-cue-color, white));
  --cue-bg-color: var(--media-user-text-bg, var(--media-cue-bg, rgba(0, 0, 0, 0.7)));
  --cue-default-font-size: var(--media-cue-font-size, calc(var(--overlay-height) / 100 * 4.5));
  --cue-font-size: calc(var(--cue-default-font-size) * var(--media-user-font-size, 1));
  --cue-line-height: var(--media-cue-line-height, calc(var(--cue-font-size) * 1.2));
  --cue-padding-x: var(--media-cue-padding-x, calc(var(--cue-font-size) * 0.6));
  --cue-padding-y: var(--media-cue-padding-x, calc(var(--cue-font-size) * 0.4));
  --cue-padding: var(--cue-padding-y) var(--cue-padding-x);
  position: absolute;
  inset: 0;
  z-index: 1;
  contain: layout style;
  margin: var(--overlay-padding);
  font-size: var(--cue-font-size);
  font-family: var(--media-user-font-family, sans-serif);
  box-sizing: border-box;
  pointer-events: none;
  user-select: none;
  word-spacing: normal;
  word-break: break-word;
}

:where([data-view-type='audio'] .vds-captions) {
  position: relative;
  margin: 0;
}

:where(.vds-captions[aria-hidden='true']) {
  opacity: 0;
  visibility: hidden;
}

/*
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * VTT Cues
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 */

:where(.vds-captions [data-part='cue-display']) {
  position: absolute;
  direction: ltr;
  overflow: visible;
  contain: content;
  top: var(--cue-top);
  left: var(--cue-left);
  right: var(--cue-right);
  bottom: var(--cue-bottom);
  width: var(--cue-width, auto);
  height: var(--cue-height, auto);
  box-sizing: border-box;
  transform: var(--cue-transform);
  text-align: var(--cue-text-align);
  writing-mode: var(--cue-writing-mode, unset);
  white-space: pre-line;
  unicode-bidi: plaintext;
  min-width: min-content;
  min-height: min-content;
  padding: var(--media-cue-display-padding);
  background-color: var(--media-user-display-bg, var(--media-cue-display-bg));
  border-radius: var(--media-cue-display-border-radius);
}

:where(.vds-captions[data-dir='rtl'] [data-part='cue-display']) {
  direction: rtl;
}

:where(.vds-captions [data-part='cue']) {
  display: inline-block;
  contain: content;
  font-variant: var(--media-user-font-variant);
  border: var(--media-cue-border, unset);
  border-radius: var(--media-cue-border-radius, 2px);
  backdrop-filter: var(--media-cue-backdrop, blur(8px));
  padding: var(--cue-padding);
  line-height: var(--cue-line-height);
  background-color: var(--cue-bg-color);
  box-sizing: border-box;
  color: var(--cue-color);
  box-shadow: var(--media-cue-box-shadow, var(--cue-box-shadow));
  white-space: var(--cue-white-space, pre-wrap);
  outline: var(--cue-outline);
  text-shadow: var(--media-user-text-shadow, var(--cue-text-shadow));
}

:where(.vds-captions [data-part='cue-display'][data-vertical] [data-part='cue']) {
  --cue-padding: var(--cue-padding-x) var(--cue-padding-y);
}

/*
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * VTT Regions
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 */

:where(.vds-captions [data-part='region']) {
  --anchor-x-percent: calc(var(--region-anchor-x) / 100);
  --anchor-x: calc(var(--region-width) * var(--anchor-x-percent));
  --anchor-y-percent: calc(var(--region-anchor-y) / 100);
  --anchor-y: calc(var(--region-height) * var(--anchor-y-percent));
  --vp-anchor-x: calc(var(--region-viewport-anchor-x) * 1%);
  --vp-anchor-y-percent: calc(var(--region-viewport-anchor-y) / 100);
  --vp-anchor-y: calc(var(--overlay-height) * var(--vp-anchor-y-percent));
  position: absolute;
  display: inline-flex;
  flex-flow: column;
  justify-content: flex-start;
  width: var(--region-width);
  height: var(--region-height);
  min-height: 0px;
  max-height: var(--region-height);
  writing-mode: horizontal-tb;
  top: var(--region-top, calc(var(--vp-anchor-y) - var(--anchor-y)));
  left: var(--region-left, calc(var(--vp-anchor-x) - var(--anchor-x)));
  right: var(--region-right);
  bottom: var(--region-bottom);
  overflow: hidden;
  overflow-wrap: break-word;
  box-sizing: border-box;
}

:where(.vds-captions [data-part='region'][data-active]) {
}

:where(.vds-captions [data-part='region'][data-scroll='up']) {
  justify-content: end;
}

:where(.vds-captions [data-part='region'][data-active][data-scroll='up']) {
  transition: top 0.433s;
}

:where(.vds-captions [data-part='region'] > [data-part='cue-display']) {
  position: relative;
  width: auto;
  left: var(--cue-offset);
  height: var(--cue-height, auto);
  text-align: var(--cue-text-align);
  unicode-bidi: plaintext;
  margin-top: 2px;
}

:where(.vds-captions [data-part='region'] [data-part='cue']) {
  position: relative;
  border-radius: 0px;
}

:where(.vds-chapter-title) {
  --color: var(--media-chapter-title-color, rgba(255 255 255 / 0.64));
  display: inline-block;
  font-family: var(--media-font-family, sans-serif);
  font-size: var(--media-chapter-title-font-size, 16px);
  font-weight: var(--media-chapter-title-font-weight, 400);
  color: var(--color);
  flex: 1 1 0%;
  padding-inline: 6px;
  overflow: hidden;
  text-align: start;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.vds-chapter-title::before {
  content: var(--media-chapter-title-separator, '\2022');
  display: inline-block;
  margin-right: var(--media-chapter-title-separator-gap, 6px);
  color: var(--media-chapter-title-separator-color, var(--color));
}

.vds-chapter-title:empty::before {
  content: '';
  margin: 0;
}

/*
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * Controls
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 */

:where(.vds-controls),
:where(.vds-controls-group) {
  position: relative;
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
}

/*
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * Audio Controls
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 */

:where([data-view-type='audio'] .vds-controls) {
  display: inline-block;
  max-width: 100%;
}

/*
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * Video Controls
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 */

:where([data-view-type='video'] .vds-controls) {
  display: flex;
  position: absolute;
  flex-direction: column;
  inset: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  padding: var(--media-controls-padding, 0px);
  transition: var(--media-controls-out-transition, opacity 0.2s ease-out);
}

:where([data-view-type='video'] .vds-controls[data-visible]) {
  opacity: 1;
  visibility: visible;
  transition: var(--media-controls-in-transition, opacity 0.2s ease-in);
}

:where(.vds-controls-spacer) {
  flex: 1 1 0%;
  pointer-events: none;
}

/*
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * Gesture
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 */

:where(.vds-gestures) {
  display: contents;
}

:where(.vds-gesture) {
  position: absolute;
  display: block;
  contain: content;
  z-index: 0;
  opacity: 0;
  visibility: hidden;
  pointer-events: none !important;
}

:where(.vds-icon svg) {
  display: block;
  width: 100%;
  height: 100%;
  vertical-align: middle;
}

/*
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * Keyboard Action
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 */

:where(.vds-kb-action.hidden) {
  display: none;
}

/*
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * Keyboard Text
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 */

:where(.vds-kb-text-wrapper) {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: var(--media-kb-text-top, 10%);
  z-index: 20;
  pointer-events: none;
}

:where(.vds-kb-text) {
  display: inline-block;
  padding: var(--media-kb-text-padding, 10px 20px);
  font-size: var(--media-kb-text-size, 150%);
  font-family: var(--media-font-family, sans-serif);
  color: var(--media-kb-text-color, #eee);
  background: var(--media-kb-text-bg, rgba(0, 0, 0, 0.5));
  border-radius: var(--media-kb-border-radius, 2.5px);
  pointer-events: none;
}

:where(.vds-kb-text:empty) {
  display: none;
}

/*
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * Keyboard Bezel
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 */

:where(.vds-kb-bezel) {
  --size: var(--media-kb-bezel-size, 52px);
  position: absolute;
  left: 50%;
  top: 45%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: var(--size);
  height: var(--size);
  margin-left: calc(-1 * calc(var(--size) / 2));
  margin-right: calc(-1 * calc(var(--size) / 2));
  z-index: 20;
  background: var(--media-kb-bezel-bg, rgba(0, 0, 0, 0.5));
  border-radius: var(--media-kb-bezel-border-radius, calc(var(--size) / 2));
  animation: var(--media-kb-bezel-animation, vds-bezel-fade 0.35s linear 1 normal forwards);
  pointer-events: none;
}

:where(.vds-kb-bezel:has(slot:empty)) {
  display: none;
}

:where(.vds-kb-action[data-action='seek-forward'] .vds-kb-bezel) {
  top: 45%;
  left: unset;
  right: 10%;
}

:where(.vds-kb-action[data-action='seek-backward'] .vds-kb-bezel) {
  top: 45%;
  left: 10%;
}

/*
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * Keyboard Icon
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 */

:where(.vds-kb-icon) {
  --size: var(--media-kb-icon-size, 38px);
  width: var(--size);
  height: var(--size);
}

@keyframes vds-bezel-fade {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(2);
  }
}

/*
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * Menus
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 */

:where(.vds-menu:not([data-submenu]) media-menu:not([data-submenu])) {
  display: contents;
}

:where(.vds-menu) {
  font-family: var(--media-font-family, sans-serif);
  font-size: var(--media-menu-font-size, 14px);
  font-weight: var(--media-menu-font-weight, 500);
}

:where(.vds-menu[data-disabled]:not([data-submenu])) {
  display: none;
}

:where(.vds-menu[data-submenu]) {
  display: inline-block;
}

:where(.vds-menu-items:focus) {
  outline: none;
}

:where(.vds-menu-items) :where([role='menuitem']:focus, [role='menuitemradio']:focus) {
  outline: none;
}

:where(.vds-menu-items)
  :where(
    [role='menuitem']:focus-visible,
    [role='menuitem'][data-focus],
    [role='menuitemradio']:focus-visible,
    [role='menuitemradio'][data-focus]
  ),
:where(.vds-radio[data-focus]) {
  outline: none;
  box-shadow: var(--media-focus-ring);
}

:where(.vds-menu[data-open] .vds-tooltip-content) {
  display: none !important;
}

/*
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * Scroll
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 */

@media (prefers-reduced-motion: no-preference) {
  :where(.vds-menu-items) {
    scroll-behavior: smooth;
  }
}

:where(.vds-menu-items) {
  --vds-scrollbar-thumb: var(--media-menu-scrollbar-thumb-bg, rgb(245 245 245 / 0.1));
  box-sizing: border-box;
  min-width: var(--media-menu-min-width, 280px);
  scrollbar-width: thin;
  scrollbar-color: var(--vds-scrollbar-thumb) var(--media-menu-scrollbar-track-bg, transparent);
}

:where(.vds-menu-items)::-webkit-scrollbar {
  background-color: black;
  border-radius: var(--media-menu-border-radius, 8px);
  height: 6px;
  width: 5px;
}

:where(.vds-menu-items)::-webkit-scrollbar-track {
  background-color: var(--media-menu-scrollbar-track-bg, rgb(245 245 245 / 0.08));
  border-radius: 4px;
}

:where(.vds-menu-items)::-webkit-scrollbar-thumb {
  background-color: var(--vds-scrollbar-thumb);
  border-radius: 4px;
}

:where(.vds-menu-items)::-webkit-scrollbar-corner {
  background-color: var(--vds-scrollbar-thumb);
}

/*
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * Menu Button
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 */

:where(.vds-menu-button) {
  outline: none;
}

:where(.vds-menu-button[role='button'] .vds-rotate-icon) {
  transition: transform 0.2s ease-out;
}

:where(.vds-menu-button[aria-expanded='true'] .vds-rotate-icon) {
  transform: rotate(var(--media-menu-button-icon-rotate-deg, 90deg));
  transition: transform 0.2s ease-in;
}

:where(.vds-menu-button[role='button']) {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

/*
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * Menu Button Parts
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 */

:where(.vds-menu-button) {
  box-sizing: border-box;
}

/* SR-only. */
:where(.vds-menu-button[role='button']) :where(.vds-menu-button-label, .vds-menu-button-hint) {
  position: absolute;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}

:where(.vds-menu-button[role='button'])
  :where(.vds-menu-button-open-icon, .vds-menu-button-close-icon) {
  display: none !important;
}

:where(.vds-menu-button) :where(.vds-menu-button-hint, .vds-menu-button-open-icon) {
  color: var(--media-menu-hint-color, rgb(245 245 245 / 0.5));
  font-size: var(--media-menu-hint-font-size, 14px);
}

/*
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * Menu Items
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 */

:where(.vds-menu-items) {
  display: flex;
  flex-direction: column;
  font-family: var(--media-font-family, sans-serif);
  font-size: var(--media-menu-font-size, 15px);
  font-weight: var(--media-menu-font-weight, 500);
  transition: height 0.35s ease;
}

:where(.vds-menu-items:not([data-submenu])) {
  padding: var(--media-menu-padding, 10px);
  background-color: var(--media-menu-bg, rgb(10 10 10));
  border-radius: var(--media-menu-border-radius, 8px);
  box-shadow: var(--media-menu-box-shadow, 1px 1px 1px rgb(10 10 10 / 0.5));
  backdrop-filter: blur(4px);
  height: var(--menu-height, auto);
  will-change: width, height;
  overflow-y: auto;
  overscroll-behavior: contain;
  opacity: 0;
  z-index: 9999999;
  box-sizing: border-box;
  max-height: var(--media-menu-max-height, 250px);
}

.vds-menu-items:not([data-submenu]) {
  border: var(--media-menu-border, 1px solid rgb(255 255 255 / 0.1));
}

:where([data-view-type='video'], .vds-video-layout) :where(.vds-menu-items:not([data-submenu])) {
  max-height: var(--media-menu-max-height, calc(var(--player-height) * 0.7));
}

:where(.vds-menu-items[data-resizing]) {
  --media-menu-scrollbar-thumb-bg: rgba(0, 0, 0, 0);
  pointer-events: none;
  overflow: hidden;
}

/*
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * Menu Items Animation
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 */

:where(.vds-menu-items:not([data-submenu])) {
  --enter-transform: translateY(0px);
  --exit-transform: translateY(12px);
}

/* Mobile Popup */
:where(.vds-menu-items:not([data-submenu]):not([data-placement])) {
  --enter-transform: translateY(-24px);
}

:where(.vds-menu-items:not([data-submenu])[aria-hidden='true']) {
  animation: var(--media-menu-exit-animation, vds-menu-exit 0.2s ease-out);
}

:where(.vds-menu-items:not([data-submenu])[aria-hidden='false']) {
  animation: var(--media-menu-enter-animation, vds-menu-enter 0.3s ease-out);
  animation-fill-mode: forwards;
}

/* Bottom */
:where(.vds-menu-items[data-placement~='bottom']) {
  --enter-transform: translateY(0);
  --exit-transform: translateY(-12px);
}

@keyframes vds-menu-enter {
  from {
    opacity: 0;
    transform: var(--exit-transform);
  }
  to {
    opacity: 1;
    transform: var(--enter-transform);
  }
}

@keyframes vds-menu-exit {
  from {
    opacity: 1;
    transform: var(--enter-transform);
  }
  to {
    opacity: 0;
    transform: var(--exit-transform);
  }
}

/*
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * Menu Portal
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 */

:where(media-menu-portal) {
  display: contents;
}

:where(.vds-menu-items:not([data-submenu]):not([data-placement])) {
  position: fixed;
  left: 16px;
  right: 16px;
  top: unset;
  bottom: 0;
  max-height: var(--media-sm-menu-portrait-max-height, 40vh);
  max-height: var(--media-sm-menu-portrait-max-height, 40dvh);
}

:where(.vds-menu-items:not([data-submenu]):not([data-placement])) {
  max-width: 480px;
  margin: 0 auto;
}

@media (orientation: landscape) and (pointer: coarse) {
  :where(.vds-menu-items:not([data-submenu]):not([data-placement])) {
    max-height: var(--media-sm-menu-landscape-max-height, min(70vh, 400px));
    max-height: var(--media-sm-menu-landscape-max-height, min(70dvh, 400px));
  }
}

/*
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * Submenu
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 */

:where(.vds-menu[data-submenu] .vds-menu-button) {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

:where(.vds-menu-items[data-submenu]) {
  width: 100%;
}

:where(.vds-menu[aria-hidden='true']),
:where(.vds-menu-items[data-submenu][aria-hidden='true']) {
  display: none;
}

/*
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * Menu Item
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 */

:where(.vds-menu-items) :where([role='menuitem'], [role='menuitemradio']) {
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100%;
  appearance: none;
  border-radius: var(--media-menu-item-border-radius, 2px);
  box-sizing: border-box;
}

.vds-menu-items [role='menuitem'],
.vds-menu-items [role='menuitemradio'] {
  border: var(--media-menu-item-border, 0);
  color: var(--media-menu-item-color, #f5f5f5);
  background-color: var(--media-menu-item-bg, transparent);
  padding: var(--media-menu-item-padding, 10px);
  font-size: var(--media-menu-font-size, 14px);
}

.vds-menu-items [role='menuitem']:focus-visible,
.vds-menu-items [role='menuitemradio']:focus-visible,
.vds-menu-items [role='menuitem'][data-focus],
.vds-menu-items [role='menuitemradio'][data-focus] {
  cursor: pointer;
  background-color: var(--media-menu-item-hover-bg, rgb(245 245 245 / 0.08));
}

:where(.vds-menu-items:not([data-submenu]):not([data-placement]))
  :where([role='menuitem'], [role='menuitemradio']) {
  padding: var(--media-sm-menu-item-padding, 12px);
}

@media (hover: hover) and (pointer: fine) {
  .vds-menu-items [role='menuitem']:hover,
  .vds-menu-items [role='menuitemradio']:hover {
    cursor: pointer;
    background-color: var(--media-menu-item-hover-bg, rgb(245 245 245 / 0.08));
  }
}

:where(.vds-menu-items[data-submenu]) {
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

:where(.vds-menu-button[role='menuitem'][aria-expanded='true']) {
  font-weight: bold;
  border-radius: 0;
  border-top-left-radius: var(--media-menu-item-border-radius, 2px);
  border-top-right-radius: var(--media-menu-item-border-radius, 2px);
  border-bottom: var(--media-menu-divider, 1px solid rgb(245 245 245 /0.15));
}

:where(.vds-menu-button[role='menuitem'][aria-expanded='true']) {
  position: sticky;
  top: calc(-1 * var(--media-menu-padding, 10px));
  left: 0;
  width: 100%;
  z-index: 10;
  backdrop-filter: blur(4px);
  margin-bottom: 4px;
}

.vds-menu-button[role='menuitem'][aria-expanded='true'] {
  background-color: var(--media-menu-top-bar-bg, rgb(10 10 10 / 0.6));
}

/*
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * Menu Item Parts
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 */

:where(.vds-menu-items [role='menuitem'] .vds-menu-button-icon) {
  width: var(--media-menu-item-icon-size, 22px);
  height: var(--media-menu-item-icon-size, 22px);
  margin-right: var(--media-menu-item-icon-spacing, 6px);
}

:where(.vds-menu-items [role='menuitem'] .vds-menu-button-close-icon) {
  margin-right: var(--media-menu-item-icon-spacing, 6px);
}

:where(.vds-menu-items [role='menuitem'])
  :where(.vds-menu-button-open-icon, .vds-menu-button-close-icon) {
  width: 18px;
  height: 18px;
}

:where(.vds-menu-items [role='menuitem'])
  :where(.vds-menu-button-hint, .vds-menu-button-open-icon) {
  margin-left: auto;
}

:where(.vds-menu-items [role='menuitem'])
  :where(.vds-menu-button-hint + .vds-menu-button-open-icon),
:where(.vds-menu-button-hint + media-icon .vds-menu-button-open-icon),
:where(.vds-menu-button-hint + slot > .vds-menu-button-open-icon) {
  margin-left: 2px;
}

:where(.vds-menu-items [role='menuitem'][aria-hidden='true']),
:where(.vds-menu-items [role='menuitem'][aria-expanded='true'] .vds-menu-button-open-icon) {
  display: none !important;
}

:where(.vds-menu-items)
  :where([role='menuitem'][aria-disabled='true'] [role='menuitem'][data-disabled])
  :where(.vds-menu-button-open-icon) {
  opacity: 0;
}

:where(.vds-menu-button .vds-menu-button-close-icon),
:where(.vds-menu-items [role='menuitem'][aria-expanded='true'] .vds-menu-button-icon) {
  display: none !important;
}

:where(.vds-menu-items [role='menuitem'][aria-expanded='true'] .vds-menu-button-close-icon) {
  display: inline !important;
  margin-left: calc(-1 * var(--media-menu-padding, 10px) / 2);
}

/*
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * Radio
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 */

:where(.vds-radio-group) {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
}

:where(.vds-radio) {
  position: relative;
  align-items: center;
  border-radius: 2px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  font-family: var(--media-font-family, sans-serif);
  font-size: 15px;
  font-weight: 500;
  contain: content;
  padding: var(--media-menu-item-padding, 12px);
}

:where(.vds-radio .vds-radio-check) {
  align-items: center;
  border-radius: 9999px;
  box-sizing: border-box;
  display: flex;
  height: var(--media-menu-radio-check-size, 9px);
  justify-content: center;
  margin-right: 8px;
  width: var(--media-menu-radio-check-size, 9px);
  border-width: unset !important; /* prevent tailwind breaking */
}

.vds-radio .vds-radio-check {
  border: var(--media-menu-radio-check-border, 2px solid rgb(245 245 245 / 0.5));
}

.vds-radio[aria-checked='true'] .vds-radio-check {
  border: 2px solid var(--media-menu-radio-check-active-color, var(--media-brand));
}

:where(.vds-radio[aria-checked='true'] .vds-radio-check)::after {
  content: '';
  background-color: var(--media-menu-radio-check-active-color, #f5f5f5);
  border-radius: 9999px;
  box-sizing: border-box;
  height: var(--media-menu-radio-check-inner-size, 4px);
  width: var(--media-menu-radio-check-inner-size, 4px);
  border-width: unset !important; /* prevent tailwind breaking */
}

.vds-radio[aria-checked='true'] .vds-radio-check::after {
  border-color: var(--media-menu-radio-check-active-color, #f5f5f5);
}

:where(.vds-radio .vds-radio-hint) {
  color: var(--media-menu-item-info-color, rgb(168, 169, 171));
  font-size: var(--media-menu-item-info-font-size, 13px);
  margin-left: auto;
}

/*
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * Chapters Menu
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 */

:where(.vds-chapters-menu-items) {
  padding: var(--media-chapters-padding, 0);
  min-width: var(--media-chapters-min-width, var(--media-menu-min-width, 220px));
}

:where(.vds-menu-items:has(.vds-chapters-radio-group[data-thumbnails])) {
  min-width: var(--media-chapters-with-thumbnails-min-width, 300px);
}

:where(.vds-chapter-radio) {
  border-radius: 0;
}

.vds-chapters-radio {
  border-bottom: var(--media-chapters-divider, 1px solid rgb(245 245 245 / 0.1));
}

:where(.vds-chapter-radio:last-child) {
  border-bottom: 0;
}

:where(.vds-chapter-radio[aria-checked='true']) {
  background-color: var(--media-chapters-item-active-bg, rgb(255 255 255 / 0.05));
  border-left: var(--media-chapters-item-active-border-left);
}

:where(.vds-chapter-radio[aria-checked='true']):after {
  content: ' ';
  width: var(--progress);
  height: var(--media-chapters-progress-height, 3px);
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: var(--media-chapters-progress-border-radius, 0);
  background-color: var(--media-chapters-progress-bg, var(--media-brand));
}

.vds-chapters-radio-group :where(.vds-thumbnail) {
  margin-right: var(--media-chapters-thumbnail-gap, 12px);
  flex-shrink: 0;
  min-width: var(--media-chapters-thumbnail-min-width, 100px);
  min-height: var(--media-chapters-thumbnail-min-height, 56px);
  max-width: var(--media-chapters-thumbnail-max-width, 120px);
  max-height: var(--media-chapters-thumbnail-max-height, 68px);
}

.vds-chapters-radio-group .vds-thumbnail {
  border: var(--media-chapters-thumbnail-border, 0);
}

:where(.vds-chapters-radio-group .vds-chapter-radio-label) {
  color: var(--media-chapters-label-color, rgb(245 245 245 / 0.64));
  font-size: var(--media-chapters-label-font-size, 15px);
  font-weight: var(--media-chapters-label-font-weight, 500);
  white-space: var(--media-chapters-label-white-space, nowrap);
}

:where(.vds-chapter-radio[aria-checked='true'] .vds-chapter-radio-label) {
  color: var(--media-chapters-label-active-color, #f5f5f5);
  font-weight: var(--media-chapters-label-active-font-weight, 500);
}

:where(.vds-chapters-radio-group .vds-chapter-radio-start-time) {
  display: inline-block;
  padding: var(--media-chapters-start-time-padding, 1px 4px);
  letter-spacing: var(--media-chapters-start-time-letter-spacing, 0.4px);
  border-radius: var(--media-chapters-start-time-border-radius, 2px);
  color: var(--media-chapters-start-time-color, rgb(168, 169, 171));
  font-size: var(--media-chapters-start-time-font-size, 12px);
  font-weight: var(--media-chapters-start-time-font-weight, 500);
  background-color: var(--media-chapters-start-time-bg, rgb(156 163 175 / 0.2));
  margin-top: var(--media-chapters-start-time-gap, 6px);
}

:where(.vds-chapters-radio-group .vds-chapter-radio-duration) {
  color: var(--media-chapters-duration-color, rgb(245 245 245 / 0.5));
  background-color: var(--media-chapters-duration-bg);
  font-size: var(--media-chapters-duration-font-size, 12px);
  font-weight: var(--media-chapters-duration-font-weight, 500);
  border-radius: var(--media-chapters-duration-border-radius, 2px);
  margin-top: var(--media-chapters-duration-gap, 6px);
}

:where(.vds-menu-button[aria-disabled='true'], .vds-menu-button[data-disabled]) {
  display: none;
}

.vds-chapters-radio-group:not([data-thumbnails]) :where(.vds-thumbnail, media-thumbnail) {
  display: none;
}

:where(.vds-chapter-radio-content) {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

:where(.vds-chapters-radio-group:not([data-thumbnails]) .vds-chapter-radio-content) {
  width: 100%;
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

:where(.vds-chapters-radio-group:not([data-thumbnails]) .vds-chapter-radio-start-time) {
  margin-top: 0;
  margin-left: auto;
}

:where(.vds-chapters-radio-group:not([data-thumbnails]) .vds-chapter-radio-duration) {
  margin-top: 4px;
  flex-basis: 100%;
}

:where(.vds-menu-items[data-keyboard]) .vds-chapters-radio-group:focus-within {
  padding: var(--media-chapters-focus-padding, 4px);
}

/*
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * Poster
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 */

:where(.vds-poster) {
  display: block;
  contain: content;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  border: 0;
  pointer-events: none;
  box-sizing: border-box;
  transition: opacity 0.2s ease-out;
  background-color: var(--media-poster-bg, black);
}

:where(.vds-poster img) {
  object-fit: inherit;
  object-position: inherit;
  pointer-events: none;
  user-select: none;
  -webkit-user-select: none;
  box-sizing: border-box;
}

.vds-poster :where(img) {
  border: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

:where(.vds-poster[data-hidden]) {
  display: none;
}

:where(.vds-poster[data-visible]) {
  opacity: 1;
}

/*
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * Sliders
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 */

:where(.vds-slider) {
  --width: var(--media-slider-width, 100%);
  --height: var(--media-slider-height, 48px);

  --thumb-size: var(--media-slider-thumb-size, 15px);
  --thumb-focus-size: var(--media-slider-focused-thumb-size, calc(var(--thumb-size) * 1.1));

  --track-width: var(--media-slider-track-width, 100%);
  --track-height: var(--media-slider-track-height, 5px);
  --track-focus-width: var(--media-slider-focused-track-width, var(--track-width));
  --track-focus-height: var(--media-slider-focused-track-height, calc(var(--track-height) * 1.25));

  display: inline-flex;
  align-items: center;
  width: var(--width);
  height: var(--height);
  /** Prevent thumb flowing out of slider. */
  margin: 0 calc(var(--thumb-size) / 2);
  position: relative;
  contain: layout style;
  outline: none;
  pointer-events: auto;
  cursor: pointer;
  user-select: none;
  touch-action: none;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: transparent;
}

:where(.vds-slider[aria-hidden='true']) {
  display: none !important;
}

:where(.vds-slider[aria-disabled='true']) {
  cursor: unset;
}

:where(.vds-slider:focus) {
  outline: none;
}

:where(.vds-slider:not([data-chapters])[data-focus], .vds-slider:not([data-chapters]):focus-visible)
  :where(.vds-slider-track) {
  box-shadow: var(--media-focus-ring);
}

:where(.vds-slider .vds-slider-track) {
  z-index: 0;
  position: absolute;
  width: var(--track-width);
  height: var(--track-height);
  top: 50%;
  left: 0;
  border-radius: var(--media-slider-track-border-radius, 2px);
  transform: translateY(-50%) translateZ(0);
  background-color: var(--media-slider-track-bg, rgb(255 255 255 / 0.3));
  contain: strict;
}

:where(.vds-slider[data-focus], .vds-slider:focus-visible) :where(.vds-slider-track) {
  outline-offset: var(--thumb-size);
}

:where(.vds-slider:not([data-chapters])[data-active] .vds-slider-track) {
  width: var(--track-focus-width);
  height: var(--track-focus-height);
}

:where(.vds-slider .vds-slider-track-fill) {
  z-index: 2; /** above track and track progress. */
  background-color: var(--media-slider-track-fill-bg, var(--media-brand));
  width: var(--slider-fill, 0%);
  will-change: width;
}

:where(.vds-slider .vds-slider-thumb) {
  position: absolute;
  top: 50%;
  left: var(--slider-fill);
  opacity: 0;
  contain: layout size style;
  width: var(--thumb-size);
  height: var(--thumb-size);
  border: var(--media-slider-thumb-border, 1px solid #cacaca);
  border-radius: var(--media-slider-thumb-border-radius, 9999px);
  background-color: var(--media-slider-thumb-bg, #fff);
  transform: translate(-50%, -50%) translateZ(0);
  transition: opacity 0.15s ease-in;
  pointer-events: none;
  will-change: left;
  z-index: 2; /** above track fill. */
}

:where(.vds-slider[data-dragging], .vds-slider[data-focus], .vds-slider:focus-visible)
  :where(.vds-slider-thumb) {
  box-shadow: var(--media-slider-focused-thumb-shadow, 0 0 0 4px hsla(0, 0%, 100%, 0.4));
}

:where(.vds-slider[data-active] .vds-slider-thumb) {
  opacity: 1;
  transition: var(--media-slider-thumb-transition, opacity 0.2s ease-in, box-shadow 0.2s ease);
}

:where(.vds-slider[data-dragging] .vds-slider-thumb) {
  width: var(--thumb-focus-size);
  height: var(--thumb-focus-size);
}

/*
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * Slider Value
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 */

:where(.vds-slider-value) {
  display: inline-block;
  contain: content;
  font-size: 14px;
  font-family: var(--media-font-family, sans-serif);
}

/*
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * Slider Thumbnail
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 */

:where(.vds-slider-thumbnail) {
  display: block;
  contain: content;
  box-sizing: border-box;
}

/*
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * Slider Video
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 */

:where(.vds-slider-video) {
  background-color: black;
  box-sizing: border-box;
  contain: content;
  display: inline-block;
  border: var(--media-thumbnail-border, 1px solid white);
}

:where(.vds-slider-video video) {
  display: block;
  height: auto;
  width: 156px;
}

/* Temporarily hide video while loading. */
:where(.vds-slider-video[data-loading]) {
  opacity: 0;
}

/* Hide video if it fails to load. */
:where(.vds-slider-video[data-hidden], .vds-slider-video[data-hidden] video) {
  display: none;
  width: 0px;
}

/*
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * Previews
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 */

:where(.vds-slider .vds-slider-preview) {
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0;
  background-color: var(--media-slider-preview-bg);
  border-radius: var(--media-slider-preview-border-radius, 2px);
  pointer-events: none;
  transition: opacity 0.2s ease-out;
  will-change: left, opacity;
  contain: layout paint style;
}

:where(.vds-slider-preview[data-visible]) {
  opacity: 1;
  transition: opacity 0.2s ease-in;
}

:where(.vds-slider-value) {
  padding: var(--media-slider-value-padding, 1px 10px);
  color: var(--media-slider-value-color, white);
  background-color: var(--media-slider-value-bg, black);
  border-radius: var(--media-slider-value-border-radius, 2px);
}

.vds-slider-value {
  border: var(--media-slider-value-border);
}

:where(
    .vds-slider-video:not([data-hidden]) + .vds-slider-chapter-title,
    .vds-slider-thumbnail:not([data-hidden]) + .vds-slider-chapter-title
  ) {
  margin-top: var(--media-slider-chapter-title-gap, 6px);
}

:where(
    .vds-slider-video:not([data-hidden]) + .vds-slider-value,
    .vds-slider-thumbnail:not([data-hidden]) + .vds-slider-value,
    .vds-slider-chapter-title + .vds-slider-value
  ) {
  margin-top: var(--media-slider-value-gap, 2px);
}

/*
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * Vertical Sliders
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 */

:where(.vds-slider[aria-orientation='vertical']) {
  --width: var(--media-slider-width, 48px);
  --height: var(--media-slider-height, 100%);

  --track-width: var(--media-slider-track-width, 4px);
  --track-height: var(--media-slider-track-height, 100%);
  --track-focus-width: var(--media-slider-focused-track-width, calc(var(--track-width) * 1.25));
  --track-focus-height: var(--media-slider-focused-track-height, var(--track-height));

  /** Prevent thumb flowing out of slider. */
  margin: calc(var(--thumb-size) / 2) 0;
}

:where(.vds-slider[aria-orientation='vertical'] .vds-slider-track) {
  top: unset;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%) translateZ(0);
}

:where(.vds-slider[aria-orientation='vertical'] .vds-slider-track-fill) {
  width: var(--track-width);
  height: var(--slider-fill);
  will-change: height;
  transform: translateX(-50%) translateZ(0);
}

:where(.vds-slider[aria-orientation='vertical'] .vds-slider-progress) {
  top: unset;
  bottom: 0;
  width: var(--track-width);
  height: var(--slider-progress, 0%);
  will-change: height;
}

:where(.vds-slider[aria-orientation='vertical'] .vds-slider-thumb) {
  top: unset;
  bottom: var(--slider-fill);
  left: 50%;
  will-change: bottom;
  transform: translate(-50%, 50%) translateZ(0);
}

:where(.vds-slider[aria-orientation='vertical'] .vds-slider-preview) {
  will-change: bottom, opacity;
}

/*
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * Time Slider
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 */

:where([data-live] .vds-time-slider .vds-slider-track-fill) {
  background-color: var(--media-slider-track-fill-live-bg, #dc2626);
}

:where(.vds-time-slider .vds-slider-progress) {
  z-index: 1; /** above track. */
  left: 0;
  width: var(--slider-progress, 0%);
  will-change: width;
  background-color: var(--media-slider-track-progress-bg, rgb(255 255 255 / 0.5));
}

:where([data-media-player]:not([data-can-play]) .vds-time-slider .vds-slider-value) {
  display: none;
}

/*
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * Slider Chapters
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 */

:where(.vds-time-slider .vds-slider-chapters) {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  contain: layout style;
  border-radius: var(--media-slider-track-border-radius, 1px);
}

:where(.vds-slider[data-focus], .vds-slider:focus-visible) :where(.vds-slider-chapters) {
  box-shadow: var(--media-focus-ring);
  height: var(--track-height);
}

:where(.vds-time-slider .vds-slider-chapter) {
  margin-right: 2px;
}

:where(.vds-time-slider .vds-slider-chapter:last-child) {
  margin-right: 0;
}

:where(.vds-time-slider .vds-slider-chapter) {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  will-change: height, transform;
  contain: layout style;
  border-radius: var(--media-slider-track-border-radius, 1px);
}

:where(.vds-time-slider .vds-slider-chapter .vds-slider-track-fill) {
  width: var(--chapter-fill, 0%);
  will-change: width;
}

:where(.vds-time-slider .vds-slider-chapter .vds-slider-progress) {
  width: var(--chapter-progress, 0%);
  will-change: width;
}

@media (hover: hover) and (pointer: fine) {
  :where(.vds-time-slider:hover .vds-slider-chapters) {
    contain: strict;
  }

  :where(.vds-time-slider .vds-slider-chapter:hover:not(:only-of-type)) {
    transform: var(--media-slider-chapter-hover-transform, scaleY(2));
    transition: var(
      --media-slider-chapter-hover-transition,
      transform 0.1s cubic-bezier(0.4, 0, 1, 1)
    );
  }
}

:where(.vds-time-slider .vds-slider-chapter-title) {
  font-family: var(--media-font-family, sans-serif);
  font-size: var(--media-slider-chapter-title-font-size, 14px);
  color: var(--media-slider-chapter-title-color, #f5f5f5);
  background-color: var(--media-slider-chapter-title-bg);
}

/*
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * Thumbnail
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 */

:where(.vds-thumbnail) {
  --aspect-ratio: calc(var(--media-thumbnail-aspect-ratio, 16 / 9));
  --min-width: var(--media-thumbnail-min-width, 140px);
  --max-width: var(--media-thumbnail-max-width, 180px);
  display: block;
  width: var(--thumbnail-width);
  height: var(--thumbnail-height);
  background-color: var(--media-thumbnail-bg, black);
  contain: strict;
  overflow: hidden;
  box-sizing: border-box;
  min-width: var(--min-width);
  min-height: var(--media-thumbnail-min-height, calc(var(--min-width) / var(--aspect-ratio)));
  max-width: var(--max-width);
  max-height: var(--media-thumbnail-max-height, calc(var(--max-width) / var(--aspect-ratio)));
}

.vds-thumbnail {
  border: var(--media-thumbnail-border, 1px solid white);
}

:where(.vds-thumbnail img) {
  min-width: unset !important;
  max-width: unset !important;
  will-change: width, height, transform;
}

:where(.vds-thumbnail[data-loading] img) {
  opacity: 0;
}

:where(.vds-thumbnail[aria-hidden='true']) {
  display: none !important;
}

/*
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * Time
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 */

:where(.vds-time-group) {
  display: flex;
  align-items: center;
}

:where(.vds-time-divider) {
  margin: 0 var(--media-time-divider-gap, 2.5px);
  color: var(--media-time-divider-color, #e0e0e0);
}

:where(.vds-time) {
  display: inline-block;
  contain: content;
  font-size: var(--media-time-font-size, 15px);
  font-weight: var(--media-time-font-weight, 400);
  font-family: var(--media-font-family, sans-serif);
  color: var(--media-time-color, #f5f5f5);
  background-color: var(--media-time-bg);
  padding: var(--media-time-padding, 2px);
  border-radius: var(--media-time-border-radius, 2px);
  letter-spacing: var(--media-time-letter-spacing, 0.025em);
}

.vds-time {
  outline: 0;
  border: var(--media-time-border);
}

:where(.vds-time:focus-visible) {
  box-shadow: var(--media-focus-ring);
}

/*
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * Tooltips
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 */

:where(.vds-tooltip, media-tooltip) {
  display: contents;
}

:where(.vds-tooltip-content) {
  display: inline-block;
  box-sizing: border-box;
  color: var(--media-tooltip-color, hsl(0, 0%, 80%));
  background-color: var(--media-tooltip-bg-color, black);
  font-family: var(--media-font-family, sans-serif);
  font-size: var(--media-tooltip-font-size, 13px);
  font-weight: var(--media-tooltip-font-weight, 500);
  opacity: 0;
  pointer-events: none;
  white-space: nowrap;
  z-index: 10;
  will-change: transform, opacity;
  border-radius: var(--media-tooltip-border-radius, 4px);
  padding: var(--media-tooltip-padding, 2px 8px);
}

.vds-tooltip-content {
  border: var(--media-tooltip-border, 1px solid rgb(255 255 255 / 0.1));
}

:where(.vds-menu .vds-menu-button[role='button'][data-pressed] .vds-tooltip-content) {
  opacity: 0;
  display: none;
}

/*
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * Tooltip Animation
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 */

:where(.vds-tooltip-content) {
  --enter-transform: translateY(0px) scale(1);
  --exit-transform: translateY(12px) scale(0.8);
}

:where(.vds-tooltip-content:not([data-visible])) {
  animation: var(--media-tooltip-exit-animation, vds-tooltip-exit 0.2s ease-out);
}

:where(.vds-tooltip-content[data-visible]) {
  animation: var(--media-tooltip-enter-animation, vds-tooltip-enter 0.2s ease-in);
  animation-fill-mode: forwards;
}

/* Bottom */
:where(.vds-tooltip-content[data-placement~='bottom']) {
  --enter-transform: translateY(0) scale(1);
  --exit-transform: translateY(-12px) scale(0.8);
}

/* Left */
:where(.vds-tooltip-content[data-placement~='left']) {
  --enter-transform: translateX(0) scale(1);
  --exit-transform: translateX(12px) scale(0.8);
}

/* Right */
:where(.vds-tooltip-content[data-placement~='right']) {
  --enter-transform: translateX(0) scale(1);
  --exit-transform: translateX(-12px) scale(0.8);
}

@keyframes vds-tooltip-enter {
  from {
    opacity: 0;
    transform: var(--exit-transform);
  }
  to {
    opacity: 1;
    transform: var(--enter-transform);
  }
}

@keyframes vds-tooltip-exit {
  from {
    opacity: 1;
    transform: var(--enter-transform);
  }
  to {
    opacity: 0;
    transform: var(--exit-transform);
  }
}

/*
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * States
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 */

/* Play Button */
[data-media-player]:not([data-paused]) .vds-play-tooltip-text,
[data-media-player][data-paused] .vds-pause-tooltip-text,
/* PIP Button */
[data-media-player][data-pip] .vds-pip-enter-tooltip-text,
[data-media-player]:not([data-pip]) .vds-pip-exit-tooltip-text,
/* Fullscreen Button */
[data-media-player][data-fullscreen] .vds-fs-enter-tooltip-text,
[data-media-player]:not([data-fullscreen]) .vds-fs-exit-tooltip-text,
/* Caption Button */
[data-media-player]:not([data-captions]) .vds-cc-on-tooltip-text,
[data-media-player][data-captions] .vds-cc-off-tooltip-text,
/* Mute Button */
[data-media-player]:not([data-muted]) .vds-mute-tooltip-text,
[data-media-player][data-muted] .vds-unmute-tooltip-text {
  display: none;
}


/* purgecss end ignore */